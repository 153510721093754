import React, { useRef, useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

const HomepageBanner = () => {
	const words = ['Design', 'Develop', 'Experience']
	const [styleState, setStyleState] = useState({
		x: null,
		width: null,
	})
	const myRef = useRef(null)
	const [index, setIndex] = useState(null)

	const enter = el => {
		el.classList.add('entering')
		el.classList.remove('exiting')
	}

	const leave = el => {
		el.classList.add('exiting')
		el.classList.remove('entering')
	}

	const handleAdvance = (labels, backgrounds, currentIndex = 0) => {
		const length = labels.length

		if (length === 0) {
			return
		}

		const nextIndex = currentIndex < length - 1 ? currentIndex + 1 : 0
		leave(labels[currentIndex])

		setTimeout(() => {
			enter(labels[nextIndex])
			enter(backgrounds[nextIndex])
			leave(backgrounds[currentIndex])
			setIndex(nextIndex)
		}, 750)

		setTimeout(() => {
			handleAdvance(labels, backgrounds, nextIndex)
		}, 5000)
	}

	const handleResize = () => {
		const element = document.querySelector('a.resizing-element')
		const rect = element.getBoundingClientRect()

		setStyleState({
			x: rect.x,
			width: `calc(${rect.width}px + (100vw - ${rect.right}px))`,
		})
	}

	useEffect(() => {
		const isReduced = window.matchMedia(`(prefers-reduced-motion: reduce)`) === true || window.matchMedia(`(prefers-reduced-motion: reduce)`).matches === true;
		handleResize()

		const labels = document.getElementsByClassName('animated-underline')
		const backgrounds = myRef.current.children
		if (!isReduced) {
			handleAdvance(labels, backgrounds, labels.length - 1)
		}
	}, [])

	useEffect(() => {
		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [styleState])

	useEffect(() => {
		const textsElements = Array.from(
			document.getElementsByClassName('hero-text'),
		)
		if (textsElements.length > 0) {
			textsElements.forEach(el => {
				const parentElement = el.closest('.word-parent')
				const parentClassName = parentElement.className
				if (parentClassName.includes('entering')) {
					el.classList.add('text-white')
					el.classList.remove('text-white/[0.3]')
				} else {
					el.classList.remove('text-white')
					el.classList.add('text-white/[0.3]')
				}
			})
		}
	}, [index])

	return (
		<div
			ref={myRef}
			className="homepage-banner items-center relative h-[60vh] md:h-[80vh] flex flex-col bg-black"
		>
			<div className="hero absolute w-full h-full banner-background brightness-[0.64] overflow-hidden">
				<StaticImage
					src="../../static/hero-images/homepage-rotation-1.jpeg"
					alt=""
					layout="fullWidth"
					className={'h-full'}
					quality={100}
					objectFit="cover"
				/>
			</div>
			<div className="hero absolute w-full h-full banner-background brightness-50 overflow-hidden">
				<StaticImage
					src="../../static/hero-images/homepage-rotation-2.jpeg"
					alt=""
					layout="fullWidth"
					className={'h-full'}
					quality={100}
					objectFit="cover"
				/>
			</div>
			<div className="hero absolute w-full h-full banner-background entering brightness-[0.64] overflow-hidden">
				<StaticImage
					src="../../static/hero-images/homepage-rotation-3.jpg"
					alt=""
					layout="fullWidth"
					className={'h-full'}
					quality={100}
					objectFit="cover"
				/>
			</div>
			<h1 className="flex-auto w-full flex flex-col justify-center sm:items-center text-5xl sm:text-[6vw]">
				<span className="banner-words sm:flex gap-3 z-10 ml-4 sm:ml-0 block">
					{words.map((word, index) => (
						<span
							className="animated-underline w-fit leading-loose  block word-parent"
							key={index}
						>
							<span className="hero-text text-white/[0.3] z-10  transition-colors ease-in-out duration-500 motion-reduce:transition-none motion-reduce:text-white">
								{word}
								<span className="text-ff_red">. </span>
							</span>
						</span>
					))}
				</span>
			</h1>

			<div className="hidden sm:flex-none sm:flex sm:justify-end">
				<Link
					to="/our-work"
					className="resizing-element absolute bottom-[4%] right-8 text-white hover:underline z-10 text-2xl lg:text-3xl"
				>
					More About What We Do →
				</Link>
				<div
					className="absolute linear-gradient-background h-[1.5%] bottom-0 right-8"
					style={{ left: styleState.x, width: styleState.width }}
				></div>
			</div>
		</div>
	)
}

export default HomepageBanner
