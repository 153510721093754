import React from 'react'

const serviceBlock = props => {
	return (
		<div className="section bg-gradient-to-b from-white to-[#F3F7FB]">
			<div className="mx-auto lg:flex justify-between">
				<div className="lg:inline-block lg:w-[35ch] xl:w-[50ch]">
					<h2 className="lg:max-w-[9ch]">Our Services</h2>
					<p className="text-md leading-7 sm:text-base">
						Our team can support any part of the development lifecycle. Our deep
						understanding of software engineering allows us to build and connect
						complicated software systems, faster. The team's in-depth knowledge
						of user experience best practices allows us to design interfaces
						that enable success efficiently. We know what to automate and we
						know when to take a hands on approach. Whether you need help
						reimagining your website, optimizing your mobile app, or improving
						your trade show presentation, our team is eager to help.
					</p>
				</div>

				<div className="sm:inline-grid mx-0 lg:ml-6 w-fit grid-cols-2 align-top gap-10 mt-10 lg:mt-0 text-[#1b1a1c]">
					<div className="pb-16">
						<div className="flex pb-9">
							<div className="pr-5">
								<img src="/icon-strategy.svg" alt="" width="64px" />
							</div>
							<h3 className="my-auto p-0">Strategy</h3>
						</div>
						<ul className="list-disc text-sm md:text-md pl-7 space-y-2">
							<li>Digital Strategy and Consulting</li>
							<li>Technical Consulting</li>
							<li>Competitive Analysis</li>
							<li>Platform Evaluation</li>
							<li>User Experience Audits</li>
							<li>Project Management</li>
						</ul>
					</div>
					<div className="pb-16">
						<div className="flex pb-9 justify-self-center">
							<div className="pr-5">
								<img src="/icon-design.svg" alt="" width="64px" />
							</div>
							<h3 className="my-auto p-0">Design</h3>
						</div>
						<ul className="list-disc text-sm md:text-md pl-7 space-y-2">
							<li>UX / UI Design</li>
							<li>Experiential Design</li>
							<li>Design Prototyping</li>
							<li>2D / 3D Animation</li>
							<li>Design Systems</li>
							<li>Visual Identity</li>
						</ul>
					</div>
					<div className="pb-16">
						<div className="flex pb-9">
							<div className="mr-5 w-[64px] h-[64px]">
								<img src="/icon-development.svg" alt="" width="64px" />
							</div>
							<h3 className="my-auto p-0">Development</h3>
						</div>
						<ul className="list-disc text-sm md:text-md pl-7 space-y-2">
							<li>Software Development</li>
							<li>Mobile, Desktop, Web</li>
							<li>Content Management Systems</li>
							<li>API Development</li>
							<li>Cloud Platform Integration</li>
							<li>Hardware Integrations</li>
							<li>AR / VR Development</li>
						</ul>
					</div>
					<div className="pb-16">
						<div className="flex pb-9">
							<div className="pr-5">
								<img src="/icon-support.svg" alt="services icon" width="64px" />
							</div>
							<h3 className="my-auto p-0">Support</h3>
						</div>
						<ul className="list-disc text-sm md:text-md pl-7 space-y-2">
							<li>Hosting and Maintenance</li>
							<li>Platform Migration</li>
							<li>Managed Updates</li>
							<li>Automated Testing</li>
							<li>Accessibility Compliance</li>
							<li>Translation Services</li>
							<li>Analytics</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	)
}

export default serviceBlock
