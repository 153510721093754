import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import HomepageBanner from '../components/homepageBanner'
import TextBlock from '../components/page_blocks/textBlock'
import FeaturedProjects from '../components/page_blocks/featuredProjects'
import ServiceBlock from '../components/page_blocks/serviceBlock'

const Index = ({ pageContext: { posts } }) => {
	return (
		<Layout isHomePage>
			<HomepageBanner />
			<TextBlock fontSize="text-xl md:text-3xl" />
			<ServiceBlock />
			<section>
				<FeaturedProjects />
			</section>
		</Layout>
	)
}

export default Index

export function Head() {
	return <Seo title={'Fast Forward'} />
}
