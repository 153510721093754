import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'

import ProjectCard from './projectCard'

const FeaturedProjects = ({ postPage }) => {
	const data = useStaticQuery(graphql`
		query featuredProjects {
			allWpPost(
				sort: { fields: [isSticky, date], order: [DESC, DESC] }
				limit: 4
			) {
				nodes {
					...BasicProjectInfo
					...CardImage
					...AdditionalPostFieldsInfo
					...ServiceInfo
				}
			}
		}
	`)

	const postsToShow = data.allWpPost.nodes
		.filter(post => post.id !== postPage)
		.slice(0, 3)

	return (
		<div className="dark-background section-wide">
			<div className="relative mx-auto">
				<div className="lg:flex items-start justify-between">
					<h2 className="inline-block">Featured Projects </h2>
					<Link
						className="text-xl next-post absolute -bottom-8 left-0 inline-block lg:relative lg:mt-2 lg:mr-[25px] lg:bottom-0"
						to="/our-work"
					>
						See All Projects
					</Link>
				</div>
				<div className="my-8 md:my-0 pb-10 lg:pb-0 grid md:grid-cols-3 md:gap-8 xl:gap-[70px] mx-auto">
					{postsToShow.map(card => (
						<ProjectCard
							title={card.title}
							label={card.additionalPostFields.label}
							services={card.services}
							slug={card.slug}
							cardImage={card.customImages?.cardImage}
							key={card.id}
						/>
					))}
				</div>
			</div>
		</div>
	)
}

export default FeaturedProjects
